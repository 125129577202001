<template>
  <div class="tw-relative tw-bg-black">
    <img
      :alt="getLocaleValue('player_popout_tv_card_control.clip_preview')"
      :src="previewImageUrl"
      :class="[videoRatio, 'tw-opacity-50']"
    />
    <div
      class="tw-width-full tw-absolute tw-inset-0 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center"
    >
      <UniversalPlayerControlWidget
        :clip="clip"
        new-design
        :class="videoRatio"
        is-video-inside-mention-card
        @play="emitPlay"
      />
    </div>
  </div>
</template>

<script lang="ts">
import type { TvClip } from "shared/types/mentions";

export type PlayerPopoutTVCardControlProps = {
  clip: TvClip;
  previewImageUrl: string;
};
</script>

<script lang="ts" setup>
import { getLocaleValue } from "shared/boot/i18n";
import { UniversalPlayerControlWidget } from "shared/components/players/UniversalPlayerControlWidget";

withDefaults(defineProps<PlayerPopoutTVCardControlProps>(), {
  previewImageUrl: "none",
});

const emit = defineEmits<{
  (e: "play"): void;
}>();

const videoRatio = "tw-block tw-w-full tw-object-cover tw-aspect-video";

function emitPlay() {
  emit("play");
}
</script>
