import type { Component } from "vue";

import type { Stream } from "shared/types";
import type { BaseMention } from "shared/types/mentions";

export type MentionCardProps<DefaultMention = BaseMention> = {
  actionSheetComponent: Component;
  alwaysPlayInPopout: boolean;
  alwaysShowKeywords: boolean;
  cardTitleClass: string;
  contentClass: string;
  dark: boolean;
  hideBorderColor: boolean;
  hideEngagement: boolean;
  hideMedia: boolean;
  icon: string;
  inMentionPreviewAlert: boolean;
  keywords: string[];
  listTitle: string;
  logo: string;
  mention: DefaultMention;
  mentions: DefaultMention[];
  noHighlighting: boolean;
  options: Record<string, unknown>;
  product: string;
  selected: boolean;
  showCheckbox: boolean;
  stream: Stream;
  title: string;
};

export const props = {
  mention: {
    type: Object,
    required: true,
  },
  mentions: {
    type: Array,
    default: () => [],
  },
  stream: {
    type: Object,
    default: () => ({}),
  },
  actionSheetComponent: {
    type: [Function, Object, String],
    default: null,
  },
  options: {
    type: Object,
    default: () => ({}),
  },
  keywords: {
    type: Array,
    default: () => [],
  },
  contentClass: {
    type: [Array, Object, String],
    default: "",
  },
  title: {
    type: String,
    default: "",
  },
  listTitle: {
    type: String,
    default: "",
  },
  showCheckbox: {
    type: Boolean,
  },
  logo: {
    type: String,
    default: "",
  },
  icon: {
    type: String,
    default: "",
  },
  baseIcon: {
    type: String,
    default: "",
  },
  dark: {
    type: Boolean,
  },
  inMentionPreviewAlert: {
    type: Boolean,
  },
  noHighlighting: {
    type: Boolean,
  },
  hideBorderColor: {
    type: Boolean,
  },
  hideEngagement: {
    type: Boolean,
  },
  hideMedia: {
    type: Boolean,
  },
  alwaysShowKeywords: {
    type: Boolean,
  },
  alwaysPlayInPopout: {
    type: Boolean,
  },
  product: {
    type: String,
    default: null,
  },
  selected: {
    type: Boolean,
  },
  cardTitleClass: {
    type: [Array, Object, String],
    default: "",
  },
};

export function propsExcept<T extends MentionCardProps>(
  keys: (keyof T)[] = []
): Partial<T> {
  const obj: Partial<T> = {};

  (Object.keys(props) as Array<keyof typeof props>).forEach((key) => {
    if (!keys.includes(key as keyof T)) {
      // TS not happy as props keys !== MentionCardProps keys (force cast here)
      obj[key as keyof T] = props[key] as T[keyof T];
    }
  });

  return obj;
}
