<template>
  <div v-if="canShowReactScore">
    <BaseButtonDropdown
      v-model="widgetOpen"
      new-design
      :placement="placement"
      flip
      :fallback-placements="['right-end', 'left-end']"
      :boundary="boundary"
      :offset="popperOffset"
      class="react-score"
      tether
    >
      <template #btn="{ toggle }">
        <div @click.stop.prevent="toggle">
          <div
            v-if="listView"
            class="tw-flex tw-flex-nowrap"
          >
            <ReactScoreIcon
              :average-score="averageScore"
              :mention="mention"
              class="tw-mr-1"
            />

            {{ averageScore }}
          </div>

          <div
            v-else
            class="tw-flex tw-flex-nowrap"
          >
            <div
              :class="
                dense ? 'tw-mr-1.5 tw-pt-1' : 'tw-ml-1.5 tw-mr-2.5 tw-pt-2'
              "
            >
              <ReactScoreIcon
                :average-score="averageScore"
                :mention="mention"
                :class="{ 'dense tw-relative': dense }"
              />
            </div>

            <div
              :class="dense ? 'tw-text-xxs' : 'tw-text-base tw-leading-5'"
              class="tw-text-left"
            >
              {{ $t("react_score_widget.react_score") }}

              <div
                class="tw-text-left tw-font-bold"
                :class="{ 'tw-text-xxs': dense }"
              >
                {{ averageScore }}
              </div>
            </div>
          </div>

          <StreemTooltip v-if="!listView">
            {{ $t("react_score_widget.react_score") }}
          </StreemTooltip>
        </div>
      </template>

      <div
        class="react-score__dropdown tw-text-left"
        @click.stop.prevent
      >
        <div class="react-score__header-container">
          <div class="react-score__title">
            <strong>{{ $t("react_score_widget.react_score") }}</strong>
          </div>

          <div
            v-if="averageScore > HIGH_REACT_SCORE_THRESHOLD"
            class="react-score__high-react-label"
          >
            {{ $t("react_score_widget.high_risk") }}
          </div>
        </div>

        <div class="react-score__score">
          {{ averageScore }}
        </div>

        <ReactScoreBar :progress="averageScore" />

        <div class="tw-leading-4">
          <div class="react-score__grid-container">
            <div
              v-for="(stat, statIndex) in stats"
              :key="statIndex"
            >
              <div class="react-score__tiles">
                <div class="column flex">
                  <div class="react-score__label-title">
                    {{ stat.label }}
                  </div>

                  <div class="react-score__value-title">
                    {{ stat.value }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="react-score__content tw-leading-4">
          {{ $t("react_score_widget.react_score_subtext") }}
        </div>
      </div>
    </BaseButtonDropdown>
  </div>
</template>

<script>
import { BaseButtonDropdown } from "shared/components/base";
import ReactScoreBar from "shared/components/ReactScoreBar.vue";
import ReactScoreIcon from "shared/components/ReactScoreIcon.vue";
import { HIGH_REACT_SCORE_THRESHOLD } from "shared/constants";

export default {
  name: "ReactScoreWidget",
  components: {
    BaseButtonDropdown,
    ReactScoreBar,
    ReactScoreIcon,
  },
  props: {
    mention: {
      type: Object,
      required: true,
    },
    isParentVisible: {
      type: Boolean,
    },
    placement: {
      type: String,
      default: "right-start",
    },
    boundary: {
      type: HTMLElement,
      default: null,
    },
    listView: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
    },
  },
  data() {
    return {
      HIGH_REACT_SCORE_THRESHOLD,
      widgetOpen: false,
    };
  },
  computed: {
    canShowReactScore() {
      return (
        this.mention.factmata_enrichment &&
        this.$features.has("has_react_score")
      );
    },
    reactScores() {
      return this.mention.factmata_enrichment || {};
    },
    stats() {
      return [
        {
          label: this.$t("react_score_widget.stats_label.harmful"),
          value: Math.round(this.reactScores.aggregate_harmful * 100),
        },
        {
          label: this.$t("react_score_widget.stats_label.controversy"),
          value: Math.round(this.reactScores.aggregate_fakeness * 100),
        },
        {
          label: this.$t("react_score_widget.stats_label.spam"),
          value: Math.round(this.reactScores.aggregate_spam * 100),
        },
        {
          label: this.$t("react_score_widget.stats_label.emotionality"),
          value: Math.round(this.reactScores.aggregate_emotionality * 100),
        },
      ];
    },
    averageScore() {
      return Math.round(this.reactScores.risk_score * 100);
    },
  },
  watch: {
    widgetOpen(value) {
      if (value) {
        this.$track("Clicked on React Score in Mention Stream", {
          score: this.averageScore,
        });
      }
    },
    isParentVisible(value) {
      if (!value) {
        this.widgetOpen = false;
      }
    },
  },
  methods: {
    popperOffset({ placement }) {
      if (placement === "right-end") {
        return [-30, -30];
      }

      return [30, -35];
    },
  },
};
</script>

<style lang="scss" scoped>
.react-score {
  --widget-font-size: 22px;
  --widget-transform: none;
  --widget-degrees: 0deg;
  --widget-hole-size: 48px;

  &__header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__high-react-label {
    background-color: $react-score-warning;
    color: white;
    width: 70px;
    height: 20px;
    border-radius: 19px;
    font-weight: 700;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__grid-container {
    display: grid;
    grid-template-columns: repeat(2, 0fr);
    gap: 8px;
    margin-top: 20px;
  }

  &__tiles {
    background-color: #e6ebef;
    border-radius: 5px;
    width: 93px;
  }

  &__title {
    font-weight: 700;
    font-size: 16px;
  }

  &__score {
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 24px;
    font-weight: 700;
  }

  &__label-title {
    margin-left: 10px;
    margin-top: 10px;
    font-weight: 400;
    font-size: 12px;
  }

  &__value-title {
    margin-top: 4px;
    margin-left: 10px;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 10px;
  }

  &__dropdown {
    width: 194px;
    border-width: 0 0 1px 1px;
    background: white;
    color: var(--s-color-font-default);
  }

  &__stat {
    display: flex;
    gap: var(--s-spacing-md);
    align-items: center;
  }

  &__content {
    margin-top: 20px;
    font-size: 14px;
  }
}

:deep(.c-base-button-dropdown__body) {
  padding: 20px 16px;
}

.dense {
  --widget-transform: scale(1.1);
}
</style>
