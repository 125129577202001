<template>
  <div
    v-if="canShowImpactScore"
    class="tw-flex tw-flex-nowrap"
  >
    <div :class="dense ? 'tw-mr-0.5 tw-pt-0.5' : 'tw-ml-1 tw-mr-2 tw-pt-1.5'">
      <BaseIcon
        :style="{ 'font-size': dense ? '20px' : '18px' }"
        icon="impact-score"
        class="text-ds-denim-9"
      />
    </div>

    <div :class="dense ? 'tw-text-xxs' : 'tw-text-base tw-leading-5'">
      {{ getLocaleText("impact_score_widget.label") }}
      <div
        class="tw-text-left tw-font-bold"
        :class="{ 'tw-text-xxs': dense }"
      >
        {{ impactScore?.grade }}
      </div>
    </div>

    <StreemTooltip>
      {{ getLocaleText("impact_score_disclaimer") }}
    </StreemTooltip>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { getLocaleText } from "shared/boot/i18n";
import { BaseIcon, StreemTooltip } from "shared/components/base";

export interface ImpactScore {
  score: number;
  grade: string;
}

const props = defineProps<{
  impactScore?: ImpactScore;
  dense?: boolean;
}>();

const canShowImpactScore = computed(() =>
  Boolean(props.impactScore && props.impactScore?.grade)
);
</script>
