<template>
  <BaseTranslationDescription>
    {{ $t("translation_description.translated_from", { fromLanguage }) }}
  </BaseTranslationDescription>
</template>

<script setup>
import BaseTranslationDescription from "./BaseTranslationDescription.vue";

defineProps({
  fromLanguage: {
    type: String,
    required: true,
  },
});
</script>
