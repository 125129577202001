<template>
  <div
    class="tw-rounded-full tw-bg-denim-900 tw-text-center tw-font-bold tw-text-white"
    :class="contentClass"
  >
    <span class="flex items-center justify-center tw-h-full tw-w-full">
      {{ currencySymbol }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { getLocaleText } from "shared/boot/i18n";
import useCurrency from "shared/composables/useCurrency";

const { currentUserCurrency } = useCurrency();

const currencySymbol = computed<string>(
  () =>
    currentUserCurrency.value?.symbolNative || getLocaleText("currency_symbol")
);

export interface AdvertisingValueIconProps {
  contentClass?: string;
}

withDefaults(defineProps<AdvertisingValueIconProps>(), {
  contentClass: "tw-text-sm tw-w-5 tw-h-5",
});
</script>
