<template>
  <div
    ref="relatedMentionCard"
    class="card tw-mt-0.5 tw-border-l-0 tw-border-r-0"
  >
    <div
      class="tw-rounded-t-none tw-rounded-bl-[4px] tw-rounded-br-[4px] tw-border-0 tw-border-l-[5px] tw-border-solid tw-border-l-transparent tw-pl-2.5"
      :class="borderClass"
    >
      <div class="tw-flex tw-items-center">
        <div class="tw-mx-0.5 tw-min-w-[45px]">
          <img
            v-if="mention.logo"
            :src="mention.logo"
            class="tw-w-[30px] tw-self-center"
            :alt="$t('global.source_logo')"
          />
        </div>
        <span
          class="tw-cursor-pointer tw-py-4 hover:tw-text-red-400"
          @click="mentionClicked(mention)"
        >
          <slot />
        </span>

        <SyndicationsPopper
          v-if="mention?.syndications?.length"
          v-slot="{ toggleOpen }"
          :mention="mention"
          :stream="stream"
          :parent-el-ref="$refs.relatedMentionCard"
        >
          <span
            v-if="mention.syndications.length > 0"
            data-testid="display-syndications-button"
            class="tw-ml-2 tw-text-primary tw-opacity-70"
            @click.stop.prevent="toggleOpen"
          >
            +{{ mention.syndications.length }}
          </span>
        </SyndicationsPopper>

        <span
          class="tw-ml-2 tw-whitespace-nowrap tw-text-[11px] tw-text-primary tw-opacity-50"
        >
          <TimeAgo
            :date="mention.published_at"
            :timezone="mention.source.time_zone"
          />
        </span>
        <div class="tw-relative tw-ml-auto tw-mr-0.5 tw-p-[3px_5px]">
          <Component
            :is="actionSheetComponent"
            :mention="mention"
            button-color="blue-grey"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

import TimeAgo from "shared/components/core/various/TimeAgo.vue";
import { SyndicationsPopper } from "shared/components/SyndicationsPopper";
import { MentionType } from "shared/types/mentions";

import type {
  RelatedArticle,
  RelatedMentionCardProps,
  Syndication,
} from "./RelatedMentionCardProps";

const props = defineProps<RelatedMentionCardProps>();

const emit = defineEmits<{
  (e: "syndication:click", item: Syndication): void;
  (e: "mention:click", item: RelatedArticle): void;
}>();

const displaySyndication = ref(false);

const mentionClicked = (mention: RelatedArticle) => {
  emit("mention:click", {
    ...mention,
    excerpts: props.mention.excerpts,
  });
};

const borderClass = computed(() => {
  const type =
    props.mention.type === MentionType.paper_article ? "print" : "online";

  return displaySyndication.value
    ? `border-color-${type} tw-pb-2.5`
    : `border-color-${type}`;
});
</script>
