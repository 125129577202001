<template>
  <div
    ref="container"
    class="ellipsis"
    data-cy="ellipseContent"
    @mouseenter="checkShowTooltip"
  >
    <slot />
    <StreemTooltip
      v-if="showTooltip"
      ref="tooltip"
      no-caret
      data-cy="ellipseContentTooltip"
      :anchor="tooltipAnchor"
      :self="tooltipSelf"
    >
      <slot
        name="tooltip"
        :is-ellipsed="isEllipsed"
      >
        <slot />
      </slot>
    </StreemTooltip>
  </div>
</template>

<script setup lang="ts">
import type { QTooltip } from "quasar";
import { nextTick, onMounted, ref } from "vue";

import StreemTooltip from "./StreemTooltip.vue";

export interface Props {
  forceTooltip?: boolean;
  tooltipAnchor?: QTooltip["anchor"];
  tooltipSelf?: QTooltip["self"];
}

const props = defineProps<Props>();

const isEllipsed = ref(false);
const showTooltip = ref(Boolean(props.forceTooltip));
const container = ref<HTMLDivElement>();
const tooltip = ref<InstanceType<typeof StreemTooltip>>();

function updateEllipsed(): void {
  isEllipsed.value =
    (container.value?.scrollWidth || 0) > (container.value?.offsetWidth || 0);
}

function checkShowTooltip(): void {
  updateEllipsed();

  showTooltip.value = props.forceTooltip || isEllipsed.value;

  // trigger tooltip manually when first shown
  if (showTooltip.value && !tooltip.value) {
    nextTick(() => {
      tooltip.value?.show();
    });
  }
}

onMounted(() => {
  updateEllipsed();
});

defineExpose({
  isEllipsed,
});
</script>
