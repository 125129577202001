<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="container flex column full-height no-wrap">
    <div class="q-pa-md">
      <div
        class="e2e-mention-title block bigger2 q-mr-12 tw-mb-2 tw-pr-4 tw-font-bold"
      >
        {{ mention.title }}
      </div>
      <div class="meta flex row no-wrap q-pb-md">
        <div
          class="tw-mb-4 tw-flex tw-flex-nowrap tw-items-start tw-justify-start"
        >
          <div class="logo tw-flex tw-h-full tw-items-center">
            <QIcon
              v-if="source.icon"
              :name="source.icon"
              size="40px"
              class="logo"
            />
            <img
              v-else
              :src="source.logo_url"
              class="logo"
              :alt="$t('similar_mentions.source_logo')"
            />
          </div>

          <div class="column col e2e-mention-details flex-basis-100">
            <span
              v-if="mentionSource"
              class="alternate q-mt-xs color-primary"
            >
              {{ mentionSource }}
              <span v-if="hasSyndication">
                + {{ mention.syndications.length }}
              </span>
            </span>
            <span class="soft block">
              <TimeAgo
                :date="mention.timestamp"
                :timezone="source.time_zone"
              />
              <template v-if="mentionAuthor">
                ·
                {{ mentionAuthor }}
              </template>
              <template v-if="mention.word_count">
                ·
                {{
                  $t("similar_mentions.word_count", {
                    wordCount: mention.word_count,
                  })
                }}
              </template>
            </span>
          </div>
        </div>
      </div>

      <MentionExcerpts
        v-if="streamId"
        :mention="mention"
      />

      <template v-if="loading">
        <div class="flex flex-center">
          <QSpinner
            v-if="loading"
            size="2em"
          />
        </div>
      </template>

      <template v-if="!loading && error">
        <QBanner
          appear
          dense
        >
          {{ $t("similar_mentions.load_similar_mentions_error") }}
          <template #action>
            <QBtn
              flat
              :label="$t('global.retry')"
              :aria-label="$t('global.retry')"
              @click="retry"
            />
          </template>
        </QBanner>
      </template>

      <template v-else>
        <div
          v-if="histogram.length"
          class="row q-mt-md q-mb-smd"
        >
          <div class="text-ds-denim-9 bold q-mb-sm">
            {{ $t("similar_mentions.chart_title") }}
          </div>

          <MentionVolumeChart
            :histogram="histogram"
            :height="160"
            flat-xticks
            class="volume-chart"
          />
        </div>
      </template>
    </div>

    <div
      class="scroll-y tw-h-full tw-grow tw-border-0 tw-border-t tw-border-solid tw-border-denim-400 tw-px-4 tw-pt-4"
    >
      <MentionStream
        v-if="stream.id"
        :default-range="range"
        :stream="stream"
        :fetching-service="SimilarMentions"
        :fetching-options="fetchingOptions"
        hide-button-bar
        hide-filters-selector
      >
        <template #noMentions>
          <div class="no-media-container column justify-center items-center">
            <strong class="q-mb-md">
              {{ $t("similar_mentions.no_similar_mentions_found") }}
            </strong>
          </div>
        </template>
      </MentionStream>
    </div>
  </div>
</template>

<script>
import addDays from "date-fns/addDays";
import subDays from "date-fns/subDays";

import MentionVolumeChart from "shared/components/charts/MentionVolumeChart.vue";
import MentionExcerpts from "shared/components/MentionExcerpts.vue";
import MentionStream from "shared/components/MentionStream.vue";
import { getTimezone } from "shared/helpers/date";
import DateRange from "shared/helpers/DateRange";
import { mediaId } from "shared/helpers/mentions";
import { capitalizeName } from "shared/helpers/string";
import SimilarMentions from "shared/services/fetching/similarMentions";

export default {
  name: "SimilarMentions",
  components: {
    MentionStream,
    MentionExcerpts,
    MentionVolumeChart,
  },
  props: {
    mention: {
      type: Object,
      required: true,
    },
    streamId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      SimilarMentions,
      histogram: [],
      loading: false,
      error: false,
      totalCount: 0,
    };
  },
  computed: {
    hasSyndication() {
      return this.mention.syndications && this.mention.syndications.length;
    },
    mentionAuthor() {
      let author = "";

      if (this.mention.author) {
        if (typeof this.mention.author === "object") {
          author = this.mention.author?.name || "";
        } else {
          author = this.mention.author;
        }
      }

      return capitalizeName(author);
    },
    source() {
      switch (this.mention.type) {
        case "tweet": {
          return {
            name: "Twitter",
            logo_url: "",
            location: "",
            icon: "ion-logo-twitter",
          };
        }
        case "instagram_post": {
          return {
            name: "Instagram",
            logo_url: "",
            location: "",
            icon: "ion-logo-instagram",
          };
        }
        case "facebook_post": {
          return {
            name: "Facebook",
            logo_url: "",
            location: "",
            icon: "ion-logo-facebook",
          };
        }
        case "youtube_video": {
          return {
            name: "Youtube",
            logo_url: "",
            location: "",
            icon: "ion-logo-youtube",
          };
        }

        default:
          return this.mention.source;
      }
    },
    mentionSource() {
      if (this.source.location) {
        return `${this.source.name} ${this.source.location}`;
      }

      return this.source.name;
    },
    range() {
      const mentionedAt = new Date(this.mention.timestamp * 1000);
      const now = new Date();

      let after = subDays(mentionedAt, 3);
      let before = addDays(mentionedAt, 3);

      if (before > now) {
        before = now;
        after = subDays(after, 3);
      }

      return DateRange.fromDates(after, before);
    },
    stream() {
      return {
        id: mediaId(this.mention),
        keywords: this.keywords.map((keyword) => `"${keyword}"`).join(" OR "),
      };
    },
    keywords() {
      return this.mention.excerpts.reduce(
        (acc, { keywords }) => [].concat(acc, keywords),
        []
      );
    },
    fetchingOptions() {
      return {
        clearMentionsCount: false,
      };
    },
  },
  watch: {
    mention: {
      deep: true,
      handler() {
        this.loadHistogram();
      },
    },
  },
  mounted() {
    this.loadHistogram();
  },
  methods: {
    async loadHistogram() {
      this.loading = true;
      this.histogram = [];

      try {
        const { headers, data } = await SimilarMentions.histogram({
          stream: this.stream,
          options: {
            range: this.range,
            time_zone: getTimezone(),
          },
        });

        this.histogram = data;
        this.totalCount = Number(headers["x-total-count"]);
      } catch {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    retry() {
      this.loadHistogram();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "shared/css/base/helpers";
@import "shared/css/utils/variables";

.volume-chart {
  width: calc(100% - 32px);
}

.meta {
  .source {
    h3 {
      margin: 0;
    }

    span {
      font-size: 14px;
    }
  }

  .logo {
    width: 40px;
    margin-right: 10px;
    color: $soft;
  }
}

:deep(.card) {
  border: 1px solid var(--s-color-denim-2);
  border-left: 0;
}
</style>
