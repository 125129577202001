<template>
  <StreemTooltip v-if="showTooltip">
    {{ getLocaleText(`universal_player_control_widget.${name}`) }}
  </StreemTooltip>
  <span
    v-else
    class="tw-group tw-ml-2 tw-text-base tw-font-normal tw-leading-7"
  >
    {{ getLocaleText(`universal_player_control_widget.${name}`) }}
  </span>
</template>

<script lang="ts" setup>
import { getLocaleText } from "shared/boot/i18n";
import StreemTooltip from "shared/components/base/StreemTooltip.vue";

import type { ButtonLabelProps } from "./types";

defineProps<ButtonLabelProps>();
</script>
