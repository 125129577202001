<template>
  <div>
    <div style="display: none">
      <slot name="components" />
    </div>

    <div v-memo="[filteredComponents.length, index, widgetKeys]">
      <div class="tw-row tw-no-wrap soft tw-mt-4 tw-flex">
        <BaseButton
          v-if="displayLeftArrow"
          icon="navigate_before"
          icon-size="md"
          new-design
          @click="updateIndex(-1)"
        />

        <Widgets />

        <BaseButton
          v-if="displayRightArrow"
          icon="navigate_next"
          icon-size="md"
          class="tw-ml-auto tw-mr-0 tw-flex tw-justify-end"
          new-design
          @click="updateIndex(1)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, h, inject, ref, useSlots } from "vue";

import { BaseButton } from "shared/components/base";

const MAX_NUMBER_OF_WIDGETS = 3;

const GRID_CLASS = "tw-grid tw-grid-cols-3 tw-w-full";

const WIDGET_CLASS =
  "tw-rounded-md tw-cursor-pointer tw-w-full tw-py-1 tw-justify-items-center";

const HOVER_STATE_CLASS = "hover:tw-bg-denim-100";

const index = ref<number>(0);

const slots = useSlots();

export interface MentionCardInsightsProps {
  slotClass?: string;
  visibleWidgets: String[];
  widgetKeys?: String[];
}

const props = withDefaults(defineProps<MentionCardInsightsProps>(), {
  slotClass: GRID_CLASS,
  visibleWidgets: () => [],
  widgetKeys: () => [],
});

function updateIndex(number: number): void {
  index.value += number;
}

const displayLeftArrow = computed(() => index.value > 0);

const displayRightArrow = computed(() => {
  if (props.visibleWidgets.length <= MAX_NUMBER_OF_WIDGETS) return false;

  return index.value + MAX_NUMBER_OF_WIDGETS < props.visibleWidgets.length;
});

const filteredComponents = computed(() =>
  slots.components!().filter((component) => {
    const type: any = component?.type;
    // eslint-disable-next-line no-underscore-dangle
    const name = type.name || type.__name;

    return props.visibleWidgets.includes(name);
  })
);

const isMobile = inject("isMobile");

const Widgets = computed(() => {
  const start = index.value;
  const end = start + MAX_NUMBER_OF_WIDGETS;

  let { slotClass } = props;

  if (filteredComponents.value.length === 2) {
    slotClass = GRID_CLASS;
  }

  let widgetClass = WIDGET_CLASS;

  if (!isMobile) {
    widgetClass = widgetClass.concat(" ", HOVER_STATE_CLASS);
  }

  const formattedComponents = filteredComponents.value
    .slice(start, end)
    .map((component) => h("div", { class: widgetClass }, component));

  return () => h("div", { class: slotClass }, formattedComponents);
});
</script>

<style lang="scss" scoped>
:deep(.new-design.base-button__icon-only) {
  min-width: 4px;
}
</style>
