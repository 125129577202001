<template>
  <span class="tw-text-denim-900">
    <p
      class="tw-mt-4 tw-select-none tw-text-denim-900"
      @click="showMatchingFragments = !showMatchingFragments"
    >
      {{
        showMatchingFragments
          ? $t("podcast_mention_card_fragments_dropdown.hide_fragments", {
              fragmentLength: fragments.length,
            })
          : $t("podcast_mention_card_fragments_dropdown.show_fragments", {
              fragmentLength: fragments.length,
            })
      }}
    </p>

    <div
      v-if="showMatchingFragments"
      class="tw-flex tw-flex-row"
    >
      <div class="tw-col tw-mr-1 tw-min-w-2 tw-rounded-md tw-bg-denim-100" />

      <div class="tw-col tw-w-full">
        <div
          v-for="fragment in fragments"
          :key="fragment.id"
          class="tw-row tw-rounded-md tw-p-2 hover:tw-bg-denim-100"
          @click.stop="fragmentClicked(fragment)"
        >
          <span class="tw-mr-1.5 tw-font-bold">
            {{ fragmentStartTime(fragment) }}
          </span>

          <span>
            {{ fragment.keyword }}

            <StreemTooltip>
              {{
                $t("podcast_mention_card_fragments_dropdown.play_audio_from", {
                  fragmentStartTime: fragmentStartTime(fragment),
                })
              }}
            </StreemTooltip>
          </span>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import { secondsToTimecodeWithSeconds, toSeconds } from "shared/helpers/date";

export default {
  name: "PodcastMentionCardFragmentsDropdown",
  props: {
    mention: {
      type: Object,
      required: true,
    },
    fragments: {
      type: Array,
      required: true,
    },
  },
  emits: ["fragment-clicked"],
  data() {
    return {
      showMatchingFragments: false,
    };
  },
  methods: {
    fragmentStartTime({ startTimeMs }) {
      return secondsToTimecodeWithSeconds(toSeconds(startTimeMs));
    },
    fragmentClicked(fragment) {
      this.$emit("fragment-clicked", fragment);
    },
  },
};
</script>
