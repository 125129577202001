<template>
  <MentionCard
    :title="mention.title"
    :mention="mention"
    :mentions="mentions"
    :stream="stream"
    :action-sheet-component="actionSheetComponent"
    :list-title="mention.title"
    :options="options"
    :no-highlighting="noHighlighting"
    :show-checkbox="showCheckbox"
    base-icon="external-item"
    :selected="selected"
    class="pointer"
    @mention-selected="$emit('mention-selected', $event)"
    @click="mentionClicked(mention)"
  >
    <template #header>
      <span class="tw-text-base tw-font-bold tw-text-denim-900">
        {{ mention.source.name }}
      </span>

      <span class="tw-text-sm tw-text-denim-900">
        <TimeAgo
          :date="mention.timestamp"
          :timezone="mention.time_zone"
        />
        ·
        {{
          $t("external_item_mention_card.added_by", {
            createdBy: mention.created_by,
            createdAt: createdAt(mention),
            displayedTimezone: displayedTimezone(mention),
          })
        }}
      </span>
    </template>

    <template #body>
      <LineClamp
        tag="span"
        :lines="5"
      >
        <span class="tw-text-base tw-leading-5 tw-text-denim-900">
          {{ mention.excerpts[0].text }}
        </span>
        <template #after="{ clamped }">
          <StreemTooltip v-if="clamped">{{
            mention.excerpts[0].text
          }}</StreemTooltip>
        </template>
      </LineClamp>
    </template>

    <slot />

    <template #list-body>
      <p>
        {{ mention.excerpts[0].text }}
      </p>

      <div class="q-my-md">
        <p class="text-ds-denim-7">
          {{
            $t("external_item_mention_card.added_by", {
              createdBy: mention.created_by,
              createdAt: createdAt(mention),
              displayedTimezone: displayedTimezone(mention),
            })
          }}
        </p>
        <span
          class="hover-active bold q-my-md"
          @click.stop="mentionClicked(mention)"
        >
          {{ $t("external_item_mention_card.view_external_item") }}
        </span>
      </div>
    </template>

    <template #afterBody>
      <div class="tw--ml-1">
        <QChip
          class="tw-mt-4 tw-bg-denim-900 tw-p-2 tw-font-bold tw-text-denim-900"
          text-color="white"
          dense
        >
          {{ $t("external_item_mention_card.external") }}
        </QChip>
      </div>

      <MentionCardInsights
        :visible-widgets="visibleWidgets"
        :widget-keys="[sentimentWidgetKey]"
        @click.stop.prevent
      >
        <template #components>
          <PotentialAudienceReachWidget
            :mention="mention"
            display-label
            dense
          />

          <AdvertisingValueWidget
            :mention="mention"
            dense
          />

          <SentimentWidget
            :key="sentimentWidgetKey"
            :mention="mention"
            :stream="stream"
            dense
          />
        </template>
      </MentionCardInsights>
    </template>

    <slot />
  </MentionCard>
</template>

<script>
import { storeToRefs } from "pinia";

import AdvertisingValueWidget from "shared/components/AdvertisingValueWidget.vue";
import { LineClamp } from "shared/components/base";
import { MentionCard, props } from "shared/components/MentionCard";
import MentionCardInsights from "shared/components/MentionCardInsights.vue";
import PotentialAudienceReachWidget from "shared/components/PotentialAudienceReachWidget.vue";
import SentimentWidget from "shared/components/SentimentWidget.vue";
import useMention from "shared/composables/useMention";
import { formatIntlDate, parseDate, shortTimezone } from "shared/helpers/date";
import { useUniversalSearchStore } from "shared/stores/universalSearch";
import { useUserStore } from "shared/stores/user";

export default {
  name: "ExternalItemMentionCard",
  components: {
    LineClamp,
    SentimentWidget,
    MentionCardInsights,
    AdvertisingValueWidget,
    PotentialAudienceReachWidget,
    MentionCard,
  },
  props,
  emits: ["mention-selected"],
  setup(mentionProps, context) {
    const { mentionClicked } = useMention(mentionProps, context);

    const universalSearchStore = useUniversalSearchStore();
    const { searchOpen } = storeToRefs(universalSearchStore);

    const userStore = useUserStore();
    const { isAdminUser } = storeToRefs(userStore);

    return { mentionClicked, isAdminUser, searchOpen };
  },
  data() {
    return {
      sentimentValue: null,
    };
  },
  computed: {
    sentimentWidgetKey() {
      return `sentiment-widget-${this.mention.id}-${this.sentimentValue}`;
    },
    visibleWidgets() {
      const widgets = ["SentimentWidget"];

      if (this.mention.audience) {
        widgets.push("PotentialAudienceReachWidget");
      }

      if (this.mention.advertising_value) {
        widgets.push("AdvertisingValueWidget");
      }

      return widgets;
    },
  },
  methods: {
    displayedTimezone(mention) {
      return shortTimezone(parseDate(mention.timestamp), mention.time_zone);
    },
    createdAt(mention) {
      return formatIntlDate(new Date(mention.created_at), {
        timeStyle: "short",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.mention-stream-view-card) {
  border-top: 1px solid $ds-denim-3;
  border-right: 1px solid $ds-denim-3;
  border-bottom: 1px solid $ds-denim-3;
}
</style>
