<template>
  <div
    class="fit relative"
    v-bind="$attrs"
  >
    <!-- NORMAL SLOT -->
    <slot v-if="state === 'done'" />
    <template v-if="state !== 'done'">
      <!-- LOADING -->
      <slot
        v-if="state === 'loading'"
        name="loading"
      >
        <div class="absolute-center">
          <QSpinner :size="30" />
        </div>
      </slot>

      <!-- ERROR -->
      <slot
        v-if="state === 'error'"
        name="error"
      >
        <div class="absolute-center">
          <div>
            <div class="q-mb-sm">
              {{ $t("global.errors.error_loading_data") }}
            </div>
            <BaseButton
              new-design
              color="primary"
              icon="ion-md-sync"
              :label="$t('global.try_again')"
              :aria-label="$t('global.try_again')"
              @click="$emit('reload')"
            />
          </div>
        </div>
      </slot>

      <!-- NO DATA -->
      <slot
        v-if="state === 'noData'"
        name="no-data"
        :no-data-message="noDataMessage"
      >
        <div class="absolute-center">
          <div class="text-center">
            {{ noDataMessage }}
          </div>
        </div>
      </slot>
    </template>
  </div>
</template>

<script setup lang="ts">
import { getLocaleText } from "shared/boot/i18n";
import BaseButton from "shared/components/base/BaseButton.vue";
import { type DataLoadingWrapperState } from "shared/types";

interface Props {
  state: DataLoadingWrapperState;
  noDataMessage?: string;
}

withDefaults(defineProps<Props>(), {
  noDataMessage: () => getLocaleText("global.no_data_available"),
});

defineEmits(["reload"]);
</script>
