<template>
  <div class="c-base-date">
    <div class="c-base-date__body">
      <div
        v-if="showRangeOptions || rangeOptionsOnly"
        class="c-base-date__range-options"
        :class="{ 'c-base-date__range-options--grid': rangeOptionsOnly }"
      >
        <template
          v-for="(option, optionKey) in localRangeOptions"
          :key="optionKey"
        >
          <BaseButton
            :label="option.localRangeLabel"
            size="md"
            new-icon
            new-design
            :menu-item="!rangeOptionsOnly"
            color="white"
            active-color="secondary"
            :active="dateRangeLabel === option.rangeLabel"
            :dense="rangeOptionsOnly"
            @click="setDateRange(option)"
          />
        </template>
      </div>

      <div
        v-if="!rangeOptionsOnly"
        class="c-base-date__main"
      >
        <template v-if="showDateInput">
          <div class="c-base-date__selections">
            <div
              v-if="rangeSelection !== 'day'"
              class="c-base-date__selection"
            >
              <div class="c-base-date__selection-title">
                {{ $t("input_date.from_title") }}
              </div>
              <div class="c-base-date__selection-inputs">
                <InputText
                  v-model="startDateDay"
                  new-design
                  class="c-base-date__selection-inputs-day"
                />
                <InputTimePicker
                  v-if="showTimeInput"
                  v-model="startDateTime"
                  :minute-step="minuteStep"
                  :min-time="firstDate"
                  :max-time="lastDate"
                  :show-seconds="showSeconds"
                  :action-button-label="actionButtonLabel"
                  class="c-base-date__selection-inputs-time"
                />
              </div>
            </div>
            <div class="c-base-date__selection">
              <div
                v-if="rangeSelection !== 'day'"
                class="c-base-date__selection-title"
              >
                {{ $t("input_date.to_title") }}
              </div>
              <div class="c-base-date__selection-inputs">
                <InputText
                  v-model="endDateDay"
                  new-design
                  class="c-base-date__selection-inputs-day"
                />
                <InputTimePicker
                  v-if="showTimeInput"
                  v-model="endDateTime"
                  :minute-step="minuteStep"
                  :min-time="firstDate"
                  :max-time="lastDate"
                  :show-seconds="showSeconds"
                  :action-button-label="actionButtonLabel"
                  class="c-base-date__selection-inputs-time"
                />
              </div>
            </div>
          </div>
          <BaseSeparator />
        </template>

        <div class="c-base-date__calendars">
          <div
            v-if="showPreviousIntervals"
            class="c-base-date__calendar"
          >
            <div class="c-base-date__month">
              <BaseButton
                new-design
                icon="fas fa-chevron-left"
                size="sm"
                icon-size="xs"
                circle
                :disabled="!canShowPreviousIntervals"
                @click="gotoPrevious"
              />
              <div>
                <BaseButton
                  new-design
                  circle
                  :label="previousIntervalLabel"
                  :aria-label="$t('input_date.previous_interval_label')"
                  @click="setInterval"
                />
              </div>
              <div />
            </div>
            <div
              v-if="currentInterval !== 'month'"
              class="c-base-date__week"
            >
              <div>{{ $t("input_date.day.sunday") }}</div>
              <div>{{ $t("input_date.day.monday") }}</div>
              <div>{{ $t("input_date.day.tuesday") }}</div>
              <div>{{ $t("input_date.day.wednesday") }}</div>
              <div>{{ $t("input_date.day.thursday") }}</div>
              <div>{{ $t("input_date.day.friday") }}</div>
              <div>{{ $t("input_date.day.saturday") }}</div>
            </div>
            <div
              class="c-base-date__intervals"
              :class="`c-base-date__intervals-${currentInterval}`"
            >
              <template
                v-for="(day, dayIndex) in previousDateIntervals"
                :key="`day_${dayIndex}`"
              >
                <div
                  class="c-base-date__intervals-interval"
                  :class="{
                    range: day.isWithinRange,
                    outside: !day.isCurrent,
                    active: day.active,
                    start: day.start,
                    end: day.end,
                    disable: day.disabled,
                    pending: day.pending,
                  }"
                  :aria-label="day.label"
                  @mouseenter="setPendingDate(day)"
                  @mouseleave="setPendingDate(null)"
                  @click="setDate(day.date, day.disabled)"
                >
                  <span>
                    {{ day.label }}
                  </span>
                </div>
              </template>
            </div>
          </div>

          <div class="c-base-date__calendar">
            <div class="c-base-date__month">
              <BaseButton
                v-if="!showPreviousIntervals"
                new-design
                icon="fas fa-chevron-left"
                size="sm"
                icon-size="xs"
                circle
                :disabled="!canShowPreviousIntervals"
                :aria-label="$t('input_date.previous_label')"
                @click="gotoPrevious"
              />
              <div v-else />
              <div>
                <BaseButton
                  new-design
                  circle
                  :label="intervalLabel"
                  :aria-label="$t('input_date.interval_label')"
                  @click="setInterval"
                />
              </div>
              <BaseButton
                new-design
                icon="fas fa-chevron-right"
                size="sm"
                icon-size="xs"
                circle
                :disabled="!canShowNextIntervals"
                :aria-label="$t('input_date.next_label')"
                @click="gotoNext"
              />
            </div>
            <div
              v-if="currentInterval !== 'month'"
              class="c-base-date__week"
            >
              <div>{{ $t("input_date.day.sunday") }}</div>
              <div>{{ $t("input_date.day.monday") }}</div>
              <div>{{ $t("input_date.day.tuesday") }}</div>
              <div>{{ $t("input_date.day.wednesday") }}</div>
              <div>{{ $t("input_date.day.thursday") }}</div>
              <div>{{ $t("input_date.day.friday") }}</div>
              <div>{{ $t("input_date.day.saturday") }}</div>
            </div>
            <div
              class="c-base-date__intervals"
              :class="`c-base-date__intervals-${currentInterval}`"
            >
              <template
                v-for="(day, dayIndex) in dateIntervals"
                :key="`day_${dayIndex}`"
              >
                <div
                  class="c-base-date__intervals-interval"
                  :class="{
                    range: day.isWithinRange,
                    outside: !day.isCurrent,
                    active: day.active,
                    start: day.start,
                    end: day.end,
                    disable: day.disabled,
                    pending: day.pending,
                  }"
                  :aria-label="day.label"
                  @mouseenter="setPendingDate(day)"
                  @mouseleave="setPendingDate(null)"
                  @click="setDate(day.date, day.disabled)"
                >
                  <span>
                    {{ day.label }}
                  </span>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <slot
      name="afterBody"
      :set-custom-date="setCustomDate"
    />

    <template v-if="showUpdateButton">
      <BaseSeparator />

      <div class="c-base-date__actions">
        <div class="c-base-date__info">
          {{ currentUserTimezone }}
        </div>
        <div class="c-base-date__actions-buttons c-base-date__actions-slot">
          <slot
            name="action"
            :set-custom-date="setCustomDate"
          />
        </div>
        <div class="c-base-date__actions-buttons c-base-date__actions-update">
          <BaseButton
            :label="$t('global.cancel')"
            new-design
            :disabled="isSelecting"
            @click="$emit('cancel')"
          />
          <BaseButton
            color="primary"
            :label="actionButtonLabel"
            :disabled="isSelecting"
            new-design
            @click="updateRange"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { getLocaleText } from "shared/boot/i18n";
import type {
  CalendarEmit,
  CalendarModel,
  CalendarProps,
} from "shared/composables/useCalendar";
import useCalendar from "shared/composables/useCalendar";
import DateRange from "shared/helpers/DateRange";

import BaseButton from "./BaseButton.vue";
import BaseSeparator from "./BaseSeparator.vue";
import InputText from "./InputText.vue";
import InputTimePicker from "./InputTimePicker.vue";

const model = defineModel<CalendarModel>({ default: null });

const props = withDefaults(defineProps<CalendarProps>(), {
  intervalView: "day",
  rangeSelection: "day",
  minuteStep: 1,
  actionButtonLabel: () => getLocaleText("input_date.default_button_label"),
});

const emit = defineEmits<CalendarEmit>();

const {
  previousDateIntervals,
  dateIntervals,
  startDateTime,
  startDateDay,
  endDateDay,
  endDateTime,
  intervalLabel,
  previousIntervalLabel,
  canShowPreviousIntervals,
  canShowNextIntervals,
  dateRangeLabel,
  isSelecting,
  currentInterval,
  firstDate,
  lastDate,
  currentUserTimezone,

  setDate,
  setCustomDate,
  setDateRange,
  setPendingDate,
  setInterval,
  gotoPrevious,
  gotoNext,
  updateRange,
} = useCalendar(model, props, { emit });

const localRangeOptions = computed<DateRange[]>(() => {
  const options = [
    DateRange.today(),
    DateRange.yesterday(),
    DateRange.lastThreeDays(),
    DateRange.lastSevenDays(),
    DateRange.lastFourteenDays(),
    DateRange.lastThirtyDays(),
    DateRange.lastNinetyDays(),
    DateRange.currentMonth(),
    DateRange.lastMonth(),
    DateRange.lastQuarter(),
  ];

  if (props.allowAllTime || props.allowLastYearShortcut) {
    options.push(DateRange.lastYear());
  }

  return options;
});
</script>

<style lang="scss" scoped>
.c-base-date {
  --interval-bg: transparent;
  --interval-label-bg: transparent;
  --interval-label-bg-hover: var(--s-button-bg-secondary-default);
  --interval-label-color: var(--s-button-color-white-default);
  --interval-label-color-hover: var(--s-button-color-white-hover);
  --interval-label-width: auto;
  --info-border: 1px solid var(--s-color-denim-2);

  display: flex;
  flex-direction: column;
  font-size: var(--s-font-size-md);

  &__body {
    display: flex;
  }

  &__info {
    display: flex;
    align-items: center;
    font-size: var(--s-font-size-sm);
    grid-area: timezone;
    border-top: var(--info-border);
    padding-block: var(--s-spacing-sm);
    padding-left: var(--s-spacing-lg);
  }

  &__actions {
    display: grid;
    grid-template: auto 1fr / auto;
    grid-template-areas:
      "slot update"
      "timezone timezone";

    &-buttons {
      display: flex;
      gap: var(--s-spacing-md);
      padding: var(--s-spacing-md);
    }

    &-slot {
      grid-area: slot;

      &:empty {
        display: none;
      }
    }

    &-update {
      grid-area: update;
      justify-content: flex-end;
    }

    &:has(.c-base-date__actions-slot:empty) {
      --info-border: none;

      grid-template-areas: "timezone update";
    }
  }

  &__range-options {
    border-right: 1px solid var(--s-color-denim-2);
    padding: var(--s-spacing-md);
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow-y: auto;

    &--grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      border-right: none;
      padding: 0;
      flex: 1;
      gap: var(--s-spacing-md);
    }
  }

  &__selections {
    display: flex;
    gap: var(--s-spacing-lg);
    padding-block: var(--s-spacing-md);
    padding-inline: var(--s-spacing-lg);
  }

  &__selection {
    display: flex;
    flex-direction: column;
    gap: var(--s-spacing-sm);
    flex: 1;

    &-title {
      font-weight: var(--s-font-weight-bold);
    }

    &-inputs {
      display: flex;
      align-items: center;
      gap: var(--s-spacing-sm);

      &-day {
        max-width: 100px;
      }

      &-time {
        max-width: 80px;
      }
    }

    &-label {
      span {
        &:nth-child(2) {
          color: var(--s-color-font-subtext);
        }
      }
    }
  }

  &__calendars {
    display: flex;
    gap: var(--s-spacing-lg);
    padding-block: var(--s-spacing-md);
    padding-inline: var(--s-spacing-lg);
  }

  &__calendar {
    display: flex;
    flex-direction: column;
    gap: var(--s-spacing-md);
    flex: 1;
    min-width: 256px;
  }

  &__month {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: var(--s-spacing-md);
  }

  &__week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: var(--s-spacing-sm);

    > * {
      text-align: center;
    }
  }

  &__intervals {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: var(--s-spacing-md) 0;

    &-month {
      --interval-label-width: 100%;

      grid-template-columns: repeat(3, 1fr);
    }

    &-interval {
      background-color: var(--interval-bg);
      display: flex;
      justify-content: center;
      position: relative;
      z-index: 1;
      cursor: pointer;

      &.range {
        --interval-bg: var(--s-button-bg-secondary-default);

        &:hover {
          --interval-label-bg-hover: var(--s-button-bg-secondary-hover);
        }
      }

      &.active {
        --interval-bg: transparent;
        --interval-label-bg: var(--s-button-bg-primary-default);
        --interval-label-bg-hover: var(--s-button-bg-primary-hover) !important;
        --interval-label-color: var(
          --s-button-color-primary-default
        ) !important;
        --interval-label-color-hover: var(--s-button-color-primary-hover);
      }

      &.pending {
        --interval-label-bg: var(--s-button-bg-primary-default);
        --interval-label-bg-hover: var(--s-button-bg-primary-default);
        --interval-label-color: var(
          --s-button-color-primary-default
        ) !important;
      }

      &.disable {
        cursor: not-allowed;

        --interval-label-color: var(--s-color-denim-5);
        --interval-label-bg-hover: transparent;
      }

      &.outside {
        --interval-label-color: var(--s-color-denim-5);
      }

      &.start,
      &.end {
        --interval-bg: transparent;

        &::before {
          content: "";
          position: absolute;
          z-index: 1;
          inset: 0 50% 0 0;
          background: var(--s-color-denim-2);
        }
      }

      &.start {
        &::before {
          right: 0;
          left: 50%;
        }
      }

      &:hover {
        --interval-label-bg: var(--interval-label-bg-hover);
      }

      span {
        width: var(--interval-label-width);
        background-color: var(--interval-label-bg);
        color: var(--interval-label-color);
        height: var(--s-size-controls-sm);
        min-width: var(--s-size-controls-sm);
        border-radius: var(--s-border-radius-full);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 10;
        transition: 150ms background-color;
      }
    }
  }
}
</style>
