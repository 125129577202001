import { defineStore } from "pinia";

import type { MediumField } from "shared/helpers/media";

export type FilterType =
  | "medium"
  | "keyword"
  | "category"
  | "group"
  | "location"
  | "topic"
  | "content";

export type FilterValueItem = {
  id: string | number;
  name: string;
};

export type FilterValue = string | number | MediumField | FilterValueItem;

export type FilterGroupIds = number[];

export interface Filter {
  type: FilterType;
  value: FilterValue;
  group_ids?: FilterGroupIds;
}

export function isFilterValueItem(
  filterValue: FilterValue
): filterValue is FilterValueItem {
  return typeof filterValue === "object" && "id" in filterValue;
}

interface RootState {
  filters: Filter[];
}

export const useSourcesStore = defineStore("sources", {
  state: (): RootState => ({
    filters: [],
  }),
});

export default useSourcesStore;
