<template>
  <div
    ref="buttonIndicator"
    class="react-score__button-indicator"
  >
    <div
      class="react-score__indicator"
      :class="{
        'react-score__indicator--red':
          averageScore > HIGH_REACT_SCORE_THRESHOLD,
      }"
    >
      <div class="react-score__indicator-mask" />

      <div class="react-score__indicator-end">
        <div />
      </div>

      <div class="react-score__indicator-hole" />
    </div>
  </div>
</template>

<script>
import { HIGH_REACT_SCORE_THRESHOLD } from "shared/constants";

export default {
  name: "ReactScoreIcon",
  props: {
    averageScore: {
      type: Number,
      required: true,
    },
    mention: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      HIGH_REACT_SCORE_THRESHOLD,
    };
  },
  computed: {
    hasFactmataEnrichment() {
      return (
        this.mention.factmata_enrichment &&
        this.$features.has("has_react_score")
      );
    },
  },
  mounted() {
    if (this.hasFactmataEnrichment) {
      this.$nextTick(() => {
        this.rotateIndicator(this.$refs.buttonIndicator, this.averageScore);
      });
    }
  },
  methods: {
    rotateIndicator(indicator, value) {
      if (!indicator) return;

      const degrees = Math.round(3.6 * value);

      indicator.style.setProperty("--widget-degrees", `${degrees}deg`);
    },
  },
};
</script>

<style lang="scss" scoped>
.react-score {
  &__button-indicator {
    --widget-hole-size: 8px;

    width: 14px;
    height: 14px;
  }

  &__indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    border-radius: 100%;
    background-color: #3e4860;
    transform: var(--widget-transform);

    &--red {
      background-color: $react-score-warning;
    }

    &-mask {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 16px;
      width: 16px;
      border-radius: 100%;
      background-image: conic-gradient(
        transparent var(--widget-degrees),
        #bac0ca var(--widget-degrees)
      );
      position: absolute;
    }

    &-end {
      height: 16px;
      width: 16px;
      position: absolute;
      display: flex;
      justify-content: center;
      transform: rotate(var(--widget-degrees));

      div {
        margin-right: 4px;
        height: 11px;
        width: 5px;
        background-image: radial-gradient(
          farthest-side at 0 4px,
          transparent 100%,
          #cccfd2 100%
        );
      }
    }

    &-hole {
      display: flex;
      align-items: center;
      justify-content: center;
      height: var(--widget-hole-size);
      width: var(--widget-hole-size);
      border-radius: 100%;
      background-color: #fff;
      z-index: 2;
      font-size: var(--widget-font-size);
      font-weight: var((--s-font-weight-bold));
      line-height: 1;
      letter-spacing: -0.04em;
      color: #34404b;
    }
  }
}
</style>
