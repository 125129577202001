<template>
  <div class="tw-flex tw-flex-col">
    <div class="tw-relative">
      <svg>
        <circle
          :cx="cx"
          :cy="cy"
          :r="radius"
          class="tw-fill-none"
        />
        <circle
          :cx="cx"
          :cy="cy"
          :r="radius"
          class="tw-fill-none"
          :style="`--percent: ${percentage}`"
        />
      </svg>

      <slot name="text" />
    </div>
  </div>
</template>

<script lang="ts">
interface PotentialAudienceReachCircularProgressProps {
  percentage: number;
  cx?: number;
  cy?: number;
  radius?: number;
}
</script>

<script setup lang="ts">
withDefaults(defineProps<PotentialAudienceReachCircularProgressProps>(), {
  cx: 46,
  cy: 32,
  radius: 28,
});
</script>

<style lang="scss" scoped>
svg {
  circle {
    /* Base circle styles */
    stroke: var(--s-color-blue-6);
    stroke-width: 8;
    stroke-linecap: square;

    /* Background circle */
    &:first-of-type {
      stroke: var(--s-color-blue-6);
    }

    /* Progress circle */
    &:last-of-type {
      stroke: var(--s-color-blue-8);
      stroke-dasharray: 160px;
      stroke-dashoffset: calc(160px - (160px * var(--percent)) / 100);
    }
  }
}
</style>
