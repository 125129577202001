<template>
  <span
    v-if="mention.audience"
    class="flex no-wrap"
  >
    <div
      class="row"
      @mouseover="popperVisible = true"
      @mouseout="popperVisible = false"
    >
      <div class="tw-flex tw-flex-nowrap">
        <div
          :class="
            dense ? 'tw-mr-0.5 tw-pt-[1px]' : 'tw-ml-[5px] tw-mr-2 tw-pt-1.5'
          "
        >
          <BaseIcon
            :style="{ 'font-size': dense ? '21px' : '18px' }"
            icon="followers"
            class="text-ds-denim-9"
          />
        </div>

        <div :class="dense ? 'tw-text-xxs' : 'tw-text-base tw-leading-5'">
          {{ $t("potential_audience_reach_widget.short_label") }}

          <div
            class="tw-text-left tw-font-bold"
            :class="{ 'tw-text-xxs': dense }"
          >
            {{ $n(mention.audience, "suffix") }}
          </div>
        </div>
      </div>
    </div>

    <Popper
      v-if="isCisionOne"
      v-model="popperVisible"
      :offset="[0, 10]"
      tether
      caret
      flip
      class="tw-rounded-md tw-bg-white tw-shadow-[10px_10px_10px_10px_rgba(0,0,0,0.1)]"
    >
      <div class="tw-flex tw-flex-row tw-p-2">
        <div class="tw-flex tw-flex-col">
          <span class="tw-mb-2 tw-text-denim-900">
            {{ $t("potential_audience_reach_widget.total_reach") }}
          </span>

          <div class="tw-text-blue-600">
            <span class="tw-text-left tw-font-bold">
              {{ formattedNumber(mention.mobile_monthly_visits) }}
            </span>

            {{ $t("potential_audience_reach_widget.mobile") }}
          </div>

          <div class="tw-text-blue-800">
            <span class="tw-text-left tw-font-bold">
              {{ formattedNumber(mention.desktop_monthly_visits) }}
            </span>

            {{ $t("potential_audience_reach_widget.desktop") }}
          </div>
        </div>

        <PotentialAudienceReachCircularProgress
          :percentage="percentage"
          class="circular-progress"
        >
          <template #text>
            <div class="text tw-absolute tw-top-6 tw-text-center tw-font-bold">
              {{ formattedNumber(mention.audience) }}
            </div>
          </template>
        </PotentialAudienceReachCircularProgress>
      </div>
    </Popper>

    <StreemTooltip v-else>
      <div class="q-pa-sm">
        <MetricEditorInfoAlert
          show-audience-multiplier-info
          class="q-mb-sm"
        />

        <div>
          {{
            $t("print_mention_card.potential_audience_reach", {
              audience: formattedNumber(mention.audience),
            })
          }}
        </div>
      </div>
    </StreemTooltip>
  </span>
</template>

<script>
import { storeToRefs } from "pinia";

import { BaseIcon, Popper } from "shared/components/base";
import PotentialAudienceReachCircularProgress from "shared/components/PotentialAudienceReachCircularProgress.vue";
import MetricEditorInfoAlert from "shared/components/user/MetricEditorInfoAlert.vue";
import { useGlobalStore } from "shared/stores/global";

export default {
  name: "PotentialAudienceReachWidget",
  components: {
    BaseIcon,
    PotentialAudienceReachCircularProgress,
    MetricEditorInfoAlert,
    Popper,
  },
  props: {
    mention: {
      type: Object,
      required: true,
    },
    dense: {
      type: Boolean,
    },
  },
  setup() {
    const globalStore = useGlobalStore();
    const { appConfig } = storeToRefs(globalStore);

    return {
      appConfig,
    };
  },
  data() {
    return {
      popperVisible: false,
    };
  },
  computed: {
    isCisionOne() {
      return this.appConfig.platformName === "CisionOne";
    },
    percentage() {
      return (
        (this.mention.desktop_monthly_visits / this.mention.audience) * 100
      );
    },
  },
  methods: {
    formattedNumber(audience) {
      return this.$n(Number(audience), "suffix");
    },
  },
};
</script>

<style lang="scss" scoped>
.circular-progress {
  width: 78px;
  height: 62px;

  .text {
    width: 90px;
  }
}
</style>
