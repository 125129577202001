<template>
  <div
    v-if="newDesign"
    :class="[
      'tw-cursor tw-group tw-flex tw-min-h-12 tw-justify-between tw-overflow-hidden tw-rounded-xl',
      baseClasses,
    ]"
    @mouseenter="togglePlayerOptions(true)"
    @mouseleave="togglePlayerOptions(false)"
  >
    <QBtn
      v-if="isAbleToAddToPlaylist"
      :icon="inPlaylist ? 'ion-md-remove' : 'ion-ios-add'"
      v-bind="buttonAttrs"
      :class="[{ 'tw-items-end': showPlayOptions }]"
      :aria-label="$t('universal_player_control_widget.add_to_queue')"
      @click="controlAction(addToPlaylist)"
    >
      <ButtonLabel
        :show-tooltip="isVideoInsideMentionCard"
        :name="addToRemoveFromQueue"
      />
    </QBtn>
    <QBtn
      v-else
      :icon="isPlaying ? 'ion-ios-pause' : 'ion-ios-play'"
      :class="[
        isPlaying ? 'pause-button' : 'play-button',
        { 'tw-items-start': showPlayOptions },
      ]"
      v-bind="buttonAttrs"
      :aria-label="$t('universal_player_control_widget.play')"
      @click="controlAction(togglePlay)"
    >
      <ButtonLabel
        v-if="!isVideoInsideMentionCard"
        :name="isPlaying ? 'pause' : 'play'"
      />
    </QBtn>
    <QBtn
      v-if="showPlayOptions && $isDesktop"
      :icon="isPlaying ? 'ion-ios-pause' : 'ion-ios-play'"
      :class="isPlaying ? 'pause-button' : 'play-button'"
      class="tw-items-start"
      v-bind="buttonAttrs"
      :aria-label="$t('universal_player_control_widget.play')"
      @click="controlAction(togglePlay)"
    >
      <ButtonLabel
        :show-tooltip="isVideoInsideMentionCard"
        :name="isPlaying ? 'pause' : 'play'"
      />
    </QBtn>
  </div>
  <OldUniversalPlayerControlWidget
    v-else
    v-bind="$props"
    @play="$emit('play')"
  />
</template>

<script>
import { storeToRefs } from "pinia";

import { useUniversalPlayerStore } from "shared/stores/universalPlayer";

import ButtonLabel from "./ButtonLabel.vue";
import OldUniversalPlayerControlWidget from "./OldUniversalPlayerControlWidget.vue";

export default {
  name: "UniversalPlayerControlWidget",
  components: { OldUniversalPlayerControlWidget, ButtonLabel },
  props: {
    autoplay: Boolean,
    class: {
      type: String,
      default: "",
    },
    clip: {
      type: Object,
      required: true,
      validator(value) {
        return (
          ("media_url" in value || "signed_url" in value) &&
          "start_time" in value
        );
      },
    },
    isVideoInsideMentionCard: Boolean,
    newDesign: Boolean,
    origin: {
      type: Object,
      default: () => ({}),
    },
    startTime: {
      type: Number,
      default: 0,
    },
  },
  emits: ["play"],
  setup() {
    const universalPlayerStore = useUniversalPlayerStore();

    const {
      universalPlayerRef,
      playerInitialClip,
      playerClip,
      playerOpen,
      playerIsPlaying,
      playerList,
      playerPopoutRef,
    } = storeToRefs(universalPlayerStore);

    const {
      playerShow,
      playerInsertClip,
      playerAppendClip,
      playerRemoveListItem,
    } = universalPlayerStore;

    return {
      universalPlayerRef,
      playerInitialClip,
      playerClip,
      playerOpen,
      playerIsPlaying,
      playerList,
      playerPopoutRef,
      playerShow,
      playerInsertClip,
      playerAppendClip,
      playerRemoveListItem,
    };
  },
  data() {
    return {
      active: false,
      immutableClip: null,
      isPlaying: false,
      showPlayOptions: false,
    };
  },
  computed: {
    addToRemoveFromQueue() {
      if (this.isVideoInsideMentionCard) {
        return this.inPlaylist ? "remove_from_queue" : "add_to_queue";
      }

      return "queue";
    },
    baseClasses() {
      return [
        (this.isUniversalPlayerPlayingThisClip || this.$isMobile) &&
          "tw-opacity-1",

        // if it's radio player - then we will show the bg color
        !this.isVideoInsideMentionCard && "tw-bg-denim-100",
      ].filter(Boolean);
    },
    buttonAttrs() {
      return {
        class: [
          "tw-grow tw-w-full !tw-px-2",
          { "tw-text-white": this.isVideoInsideMentionCard },
        ],
        dense: true,
        flat: true,
        round: true,
        size: this.isVideoInsideMentionCard ? "25px" : undefined,
      };
    },
    playerRef() {
      return this.universalPlayerRef;
    },
    inPlaylist() {
      if (this.playerList) {
        return this.playerList.some((listItem) => listItem.id === this.clip.id);
      }

      return false;
    },
    isUniversalPlayerPlayingThisClip() {
      return (
        this.playerInitialClip &&
        this.clip &&
        this.playerInitialClip.id === this.clip.id
      );
    },
    isAbleToAddToPlaylist() {
      return this.playerIsPlaying && !this.isPlaying && this.$isDesktop;
    },
  },
  watch: {
    playerIsPlaying: {
      handler: "matchUniversalPlayerState",
    },
    playerClip: {
      handler: "matchUniversalPlayerState",
    },
    playerOpen() {
      if (!this.playerOpen) this.paused();
    },
    startTime() {
      if (this.startTime && this.active) {
        this.play();
        if (this.startTime) this.updatePlayerStart();
        this.$emit("play");
      }
    },
  },
  activated() {
    this.active = true;
    this.matchUniversalPlayerState();
  },
  deactivated() {
    this.active = false;
    this.isPlaying = false;
  },
  mounted() {
    this.active = true;
    this.immutableClip = { ...this.clip, ...{ origin: this.origin } };
    this.matchUniversalPlayerState();

    if (this.autoplay && !this.playerIsPlaying) {
      this.togglePlay();
    }
  },
  methods: {
    updatePlayerStart() {
      this.$nextTick().then(() => {
        let startTime = this.startTime - this.clip.start_time;

        if (startTime > this.clip.end_time - this.clip.start_time) {
          const { duration } = this.playerRef;

          startTime =
            Math.min(this.clip.end_time - this.clip.start_time, duration) - 2;
        }

        this.playerRef.currentTime = startTime;
      });
    },
    async togglePlay() {
      if (!this.active) return;

      if (this.isPlaying) {
        this.pause();
      } else {
        this.play();
        if (this.startTime) this.updatePlayerStart();
        this.$emit("play");
      }
    },
    play() {
      if (!this.inPlaylist) this.playerInsertClip(this.immutableClip);
      if (!this.playerOpen) this.playerShow();
      this.$nextTick().then(this.played);
    },
    pause() {
      this.playerRef.pause();
      this.paused();
    },
    played() {
      this.isPlaying = true;
    },
    paused() {
      this.isPlaying = false;
    },
    addToPlaylist() {
      if (this.inPlaylist) {
        this.removeFromPlaylist();
      } else {
        this.playerAppendClip(this.immutableClip);
      }
    },
    removeFromPlaylist() {
      if (this.isUniversalPlayerPlayingThisClip) {
        this.pause();
      }

      this.playerRemoveListItem(this.clip);
    },
    matchUniversalPlayerState() {
      this.$nextTick().then(() => {
        if (this.playerIsPlaying && this.isUniversalPlayerPlayingThisClip) {
          this.played();
        } else {
          this.paused();
        }
      });
    },
    togglePlayerOptions(value) {
      if (!this.isPlaying && this.playerIsPlaying) {
        this.showPlayOptions = value;
      } else {
        this.showPlayOptions = false;
      }
    },
    controlAction(action) {
      if (
        this.$isMobile &&
        !this.isPlaying &&
        this.playerIsPlaying &&
        !this.showPlayOptions
      ) {
        this.togglePlayerOptions(true);
      } else if (typeof action === "function") {
        this.togglePlayerOptions(false);
        action();
        this.trackAction(action);
      }
    },
    trackAction(action) {
      let type = "";

      switch (this.clip.type) {
        case "podcast_episode":
          type = "podcast";
          break;
        case "radio_clip":
          type = "radio";
          break;
        default:
          type = "tv";
      }

      if (action.name === "addToPlaylist") {
        this.$track("Added a clip to the playlist", {
          clipId: this.clip.id,
          type,
        });
      } else {
        this.$track("Toggled play on a clip", {
          clipId: this.clip.id,
          type,
        });
      }
    },
  },
};
</script>
