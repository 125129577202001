<template>
  <div class="row items-center translation-description">
    <TranslationIcon
      class="translation-description-icon"
      :class="{ error }"
    />
    <div
      class="translation-description-text"
      :class="{ error }"
    >
      <slot />
    </div>
  </div>
</template>

<script setup>
import TranslationIcon from "shared/components/translation/TranslationIcon.vue";

defineProps({
  error: {
    type: Boolean,
  },
});
</script>

<style lang="scss" scoped>
.translation-description {
  &-icon {
    color: var(--s-color-denim-9);
    width: 19px;

    &.error {
      color: var(--s-color-red-5);
    }
  }

  &-text {
    color: var(--s-color-denim-9);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 12px;

    &.error {
      color: var(--s-color-red-5);
    }
  }
}
</style>
