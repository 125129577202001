<template>
  <div
    v-if="hasAmplify"
    :key="`mention-${mention.id}-${totalSocialAmplifyVolume}`"
    class="row items-baseline"
  >
    <div
      class="tw-flex tw-flex-nowrap"
      @click="onSocialAmplifyClicked"
    >
      <BaseButton
        :disabled="!totalSocialAmplifyVolume"
        new-design
        new-icon
        flat
        dense
        icon="chart-pie"
        :class="{ 'tw-ml-0.5 tw-mr-1': !dense }"
      />

      <div :class="dense ? 'tw-text-xxs' : 'tw-text-base tw-leading-5'">
        {{ getLocaleText("social_amplify_icon.social_amplify") }}

        <div :class="['tw-text-left tw-font-bold', { 'tw-text-xxs': dense }]">
          {{ totalSocialEngagementFormatted }}
        </div>
      </div>

      <StreemTooltip
        v-if="displayTooltip"
        anchor="center right"
        self="center left"
        :offset="[4, 4]"
      >
        <span v-if="!totalSocialAmplifyVolume && !enoughTimeHasPassed">
          {{
            getLocaleText(
              "social_amplify_icon.not_enough_social_data_recent_article"
            )
          }}
        </span>
        <span v-else-if="!totalSocialAmplifyVolume">
          {{ getLocaleText("social_amplify_icon.not_enough_social_data") }}
        </span>
      </StreemTooltip>
    </div>

    <SocialAmplifyPopup
      v-if="totalSocialAmplifyVolume"
      v-model="showSocialAmplifyPopup"
      :mention="mention"
      :data="socialAmplifyData"
      :total="totalSocialAmplifyVolume"
      :root-element="rootElement"
    />

    <StreemTooltip v-if="totalSocialAmplifyVolume && !showSocialAmplifyPopup">
      {{ getLocaleText("social_amplify_popup.social_amplify_tooltip") }}
    </StreemTooltip>
  </div>
</template>

<script>
import differenceInMinutes from "date-fns/differenceInMinutes";
import { storeToRefs } from "pinia";

import { getLocaleText } from "shared/boot/i18n";
import { BaseButton, StreemTooltip } from "shared/components/base";
import SocialAmplifyPopup from "shared/components/SocialAmplifyPopup.vue";
import { suffix } from "shared/helpers/number";
import { useUserStore } from "shared/stores/user";

const MINIMUM_MINUTES_TO_ELAPSE = 15;

import useSocialAmplify from "shared/composables/useSocialAmplify";

export default {
  name: "SocialAmplifyWidget",
  components: {
    BaseButton,
    SocialAmplifyPopup,
    StreemTooltip,
  },
  props: {
    mention: {
      type: Object,
      required: true,
    },
    isListView: {
      type: Boolean,
    },
    isParentVisible: {
      type: Boolean,
    },
    rootElement: {
      type: HTMLElement,
      default: null,
    },
    dense: {
      type: Boolean,
    },
    scrollIntoView: {
      type: Boolean,
    },
  },
  emits: ["click", "update-social-amplify-widget-key"],
  setup(props) {
    const {
      socialAmplifyData,
      showSocialAmplifyPopup,
      hasAmplify,
      totalSocialAmplifyVolume,
      loadSocialAmplifyData,
      toggleSocialAmplify,
    } = useSocialAmplify(props);

    const userStore = useUserStore();
    const { currentUser, isAdminUser } = storeToRefs(userStore);

    return {
      socialAmplifyData,
      showSocialAmplifyPopup,
      hasAmplify,
      totalSocialAmplifyVolume,
      loadSocialAmplifyData,
      toggleSocialAmplify,
      currentUser,
      isAdminUser,
    };
  },
  computed: {
    minutesElapsedSincePublish() {
      const now = new Date();
      const publishedAt = new Date(this.mention.published_at);

      return differenceInMinutes(now, publishedAt);
    },
    enoughTimeHasPassed() {
      return this.minutesElapsedSincePublish >= MINIMUM_MINUTES_TO_ELAPSE;
    },
    totalSocialEngagementFormatted() {
      return suffix(this.totalSocialAmplifyVolume);
    },
    displayTooltip() {
      return !this.totalSocialAmplifyVolume || !this.enoughTimeHasPassed;
    },
  },
  watch: {
    isParentVisible: {
      handler(value) {
        if (value === false) {
          this.showSocialAmplifyPopup = false;
        }
      },
    },
    totalSocialAmplifyVolume: {
      deep: true,
      handler(value) {
        this.$emit("update-social-amplify-widget-key", value);
      },
    },
  },
  mounted() {
    if (this.hasAmplify) {
      this.loadSocialAmplifyData();
    }
  },
  methods: {
    getLocaleText,
    onSocialAmplifyClicked() {
      if (!this.showSocialAmplifyPopup) {
        if (this.scrollIntoView && this.totalSocialAmplifyVolume) {
          this.rootElement.scrollIntoView({ behaviour: "smooth" });
        }

        this.$track("Opened Social Amplify");
      }

      this.toggleSocialAmplify();
    },
  },
};
</script>
