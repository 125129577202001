<template>
  <BaseButtonDropdown
    v-model="pickerOpen"
    new-design
    new-dropdown
    placement="bottom-start"
    class="c-base-time-picker"
  >
    <template #btn>
      <div @click="pickerOpen = true">
        <InputText
          v-model="time"
          new-design
          :borderless="borderless"
        >
          <template
            v-if="$slots.prepend"
            #prepend
          >
            <slot name="prepend" />
          </template>
        </InputText>
      </div>
    </template>
    <div class="c-base-time-picker__dropdown">
      <div class="c-base-time-picker__time-lists">
        <InputTimePickerList
          v-model="hourOption"
          :options="hourOptions"
          data-testid="hours-list"
        />
        <InputTimePickerList
          v-model="minuteOption"
          :options="minuteOptions"
          :step="minuteStep"
          data-testid="minutes-list"
        />
        <InputTimePickerList
          v-if="showSeconds"
          v-model="secondOption"
          :options="secondOptions"
          data-testid="seconds-list"
        />
      </div>
      <BaseSeparator />
      <div class="c-base-time-picker__footer">
        <BaseButton
          new-design
          :label="$t('global.cancel')"
          size="sm"
          @click="cancel"
        />
        <BaseButton
          new-design
          :label="actionButtonLabel"
          color="primary"
          size="sm"
          @click="ok"
        />
      </div>
    </div>
  </BaseButtonDropdown>
</template>

<script>
import { ref, watch } from "vue";

import { getLocaleText } from "shared/boot/i18n";
import useTime from "shared/composables/useTime";

import BaseButton from "./BaseButton.vue";
import BaseButtonDropdown from "./BaseButtonDropdown.vue";
import BaseSeparator from "./BaseSeparator.vue";
import InputText from "./InputText.vue";
import InputTimePickerList from "./InputTimePickerList.vue";

export default {
  name: "InputTimePicker",
  components: {
    BaseButton,
    BaseButtonDropdown,
    BaseSeparator,
    InputTimePickerList,
    InputText,
  },
  props: {
    modelValue: {
      type: Date,
      required: true,
    },
    minTime: {
      type: Date,
      default: null,
    },
    maxTime: {
      type: [Date, null],
      default: null,
    },
    timezone: {
      type: String,
      default: "",
    },
    minuteStep: {
      type: Number,
      default: 1,
    },
    showSeconds: {
      type: Boolean,
    },
    interval: {
      type: Number,
      default: 0,
    },
    borderless: {
      type: Boolean,
    },
    actionButtonLabel: {
      type: String,
      default: () => getLocaleText("global.set"),
    },
  },
  emits: ["update:modelValue"],
  setup(props, context) {
    const {
      pendingLocalTime,
      previousLocalTime,
      error,
      hourOptions,
      hourOption,
      minuteOptions,
      minuteOption,
      secondOptions,
      secondOption,

      localTime,
      newLocalTime,
      localMaxTime,
      localMinTime,
      formatString,
      time,
    } = useTime(props, context);

    const pickerOpen = ref(false);

    function cancel() {
      context.emit("update:modelValue", previousLocalTime.value);
      pickerOpen.value = false;
    }

    function ok() {
      if (!error.value) {
        previousLocalTime.value = localTime.value;
        pendingLocalTime.value = null;
      }

      pickerOpen.value = false;
    }

    watch(pickerOpen, (value) => {
      if (!value) {
        previousLocalTime.value = localTime.value;
        pendingLocalTime.value = null;
      }
    });

    return {
      pendingLocalTime,
      previousLocalTime,
      error,
      hourOptions,
      hourOption,
      minuteOptions,
      minuteOption,
      secondOptions,
      secondOption,

      localTime,
      newLocalTime,
      localMaxTime,
      localMinTime,
      formatString,
      time,

      pickerOpen,
      cancel,
      ok,
    };
  },
};
</script>

<style lang="scss" scoped>
.c-base-time-picker {
  &__dropdown {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: var(--s-spacing-sm);
  }

  &__time-lists {
    display: flex;
    overflow: hidden;
    height: calc(var(--s-size-controls-md) * 5);
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    gap: var(--s-spacing-sm);
    padding: var(--s-spacing-sm);
  }
}
</style>
