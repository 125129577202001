import type { Nullable } from "shared/types";
import type { Mention } from "shared/types/mentions";

interface State {
  selectedMention: Nullable<Mention>;
  containerOffset: string;
  orientationAngle: Nullable<string>;
  keyboardOpen: boolean;
  keyboardHeight: number;
}

export default () =>
  <State>{
    selectedMention: null,
    containerOffset: "0px",
    orientationAngle: null,
    keyboardOpen: false,
    keyboardHeight: 0,
  };
