<template>
  <component
    :is="tag"
    ref="text"
    data-testid="lineClampText"
    class="line-clamp"
    v-bind="$attrs"
    :style="lineClampStyles"
  >
    <slot :clamped="shouldClamp" />
  </component>

  <slot
    name="after"
    :clamped="shouldClamp"
  />
</template>

<script lang="ts">
import type { VNode } from "vue";
import { computed, defineSlots, nextTick, onMounted, ref } from "vue";

import type { Nullable } from "shared/types";

export interface LineClampProps {
  lines?: number;
  maxWidth?: string;
  showAll?: boolean;
  tag?: string;
}

export interface LineClampSlots {
  after: (props: { clamped: boolean }) => VNode[] | string;
  default: (props: { clamped: boolean }) => VNode[] | string;
}
</script>

<script lang="ts" setup>
const LINE_HEIGHT_NORMAL = 1.2;

const emit = defineEmits(["clamp-changed"]);

defineSlots<LineClampSlots>();

const props = withDefaults(defineProps<LineClampProps>(), {
  lines: 2,
  tag: "div",
  maxWidth: "100%",
});

const text = ref<Nullable<HTMLElement>>(null);
const shouldClamp = ref(false);

const lineClampStyles = computed(() => {
  const styles = {
    maxWidth: props.maxWidth,
  };

  if (!shouldClamp.value || props.showAll) {
    return styles;
  }

  return {
    ...styles,
    "-webkit-line-clamp": props.lines,
  };
});

function checkClamp(height: number, lineHeight: string) {
  const elLineCount = Math.floor(height / parseInt(lineHeight, 10));
  shouldClamp.value = elLineCount > props.lines;

  emit("clamp-changed", shouldClamp.value);
}

onMounted(() => {
  nextTick(() => {
    if (!text.value) return;

    const elHeight = text.value.offsetHeight;
    const styles = window.getComputedStyle(text.value);

    let lineHeight: string = styles.getPropertyValue("line-height");

    if (!lineHeight.endsWith("px")) {
      const fontSize = styles.getPropertyValue("font-size").replace("px", "");
      lineHeight = (parseFloat(fontSize) * LINE_HEIGHT_NORMAL).toString();
    } else {
      lineHeight = parseFloat(lineHeight.replace("px", "")).toString();
    }

    checkClamp(elHeight, lineHeight);
  });
});

defineExpose({ checkClamp, shouldClamp: shouldClamp.value });
</script>

<style lang="scss" scoped>
.line-clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
</style>
