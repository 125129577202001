<template>
  <QSpinner
    v-if="props.isLoading"
    class="tw-mr-3"
  />
  <div v-else>
    <BaseIcon
      v-if="props.isChecked"
      icon="check"
      :class="['tw-px-0.5 tw-text-2xl tw-text-denim-500']"
      v-bind="$attrs"
    />
    <StreemTooltip v-if="props.isChecked">
      {{ getLocaleText("syndications.syndicated_item_tooltip") }}
    </StreemTooltip>
  </div>
</template>

<script setup lang="ts">
import { QSpinner } from "quasar";

import { getLocaleText } from "shared/boot/i18n";
import BaseIcon from "shared/components/base/BaseIcon.vue";
import StreemTooltip from "shared/components/base/StreemTooltip.vue";

const props = defineProps<{
  isChecked: boolean;
  isLoading: boolean;
}>();
</script>
