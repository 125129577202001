<template>
  <div class="tw-flex tw-flex-nowrap">
    <div class="tw-mr-0.5 tw-pt-0.5">
      <QIcon
        :name="icon"
        size="20px"
        class="text-ds-denim-9"
      />
    </div>

    <div class="tw-text-xxs">
      {{ title }}

      <div class="tw-text-left tw-text-xxs tw-font-bold">{{ value }}</div>
    </div>

    <StreemTooltip>
      <slot name="tooltip" />
    </StreemTooltip>
  </div>
</template>

<script setup lang="ts">
import { StreemTooltip } from "shared/components/base";

export interface SocialInsightWidgetProps {
  icon: string;
  title: string;
  value: string;
}

defineProps<SocialInsightWidgetProps>();
</script>
