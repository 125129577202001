<template>
  <a
    v-if="mention.advertising_value"
    class="flex no-wrap"
    @click.stop.prevent
  >
    <div class="tw-flex tw-flex-nowrap">
      <AdvertisingValueIcon
        :class="dense ? 'tw-mr-0.5 tw-mt-0.5' : 'tw-ml-1.5 tw-mr-2.5 tw-mt-3'"
        :content-class="
          dense
            ? 'tw-text-sm tw-w-5 tw-h-5'
            : 'tw-text-xs tw-w-[18px] tw-h-[18px]'
        "
      />

      <div :class="dense ? 'tw-text-xxs' : 'tw-text-base tw-leading-5'">
        {{ $t("advertising_value_widget.short_label") }}

        <div
          class="tw-text-left tw-font-bold"
          :class="{ 'tw-text-xxs': dense }"
        >
          {{ compactAdvertisingValueEquivalency }}
        </div>
      </div>
    </div>

    <StreemTooltip>
      <div class="q-pa-sm">
        <MetricEditorInfoAlert
          show-ave-multiplier-info
          class="q-mb-sm"
        />
        <div>
          {{
            $t("advertising_value_widget.advertising_value_equivalency", {
              ave: advertisingValueEquivalency,
            })
          }}
        </div>
        <div class="softer smaller q-mt-sm">
          {{ $t("ave_disclaimer", { platformName: appConfig.platformName }) }}
        </div>
      </div>
    </StreemTooltip>
  </a>
</template>

<script>
import { storeToRefs } from "pinia";

import AdvertisingValueIcon from "shared/components/AdvertisingValueIcon.vue";
import MetricEditorInfoAlert from "shared/components/user/MetricEditorInfoAlert.vue";
import useCurrency from "shared/composables/useCurrency";
import { useGlobalStore } from "shared/stores/global";

export default {
  name: "AdvertisingValueWidget",
  components: {
    MetricEditorInfoAlert,
    AdvertisingValueIcon,
  },
  props: {
    mention: {
      type: Object,
      required: true,
    },
    dense: {
      type: Boolean,
    },
  },
  setup() {
    const globalStore = useGlobalStore();

    const { appConfig } = storeToRefs(globalStore);

    const { numberToCurrentUserCurrency, currentUserCurrency } = useCurrency();

    return { appConfig, numberToCurrentUserCurrency, currentUserCurrency };
  },
  computed: {
    compactAdvertisingValueEquivalency() {
      return this.$n(this.mention.advertising_value, "suffix");
    },
    advertisingValueEquivalency() {
      return this.numberToCurrentUserCurrency(this.mention.advertising_value, {
        currencyDisplay: "code",
        codeOnFront: true,
      });
    },
  },
};
</script>
