import { storeToRefs } from "pinia";
import { computed } from "vue";

import { getLocale, getLocaleNumber } from "shared/boot/i18n";
import type { Currency } from "shared/constants";
import { currencies } from "shared/constants";
import { useUserStore } from "shared/stores/user";

interface NumberToCurrency {
  locale: string | undefined;
  currencyCode: string;
  currencyDisplay: string;
  codeOnFront: boolean;
}

interface NumberToCurrentUserCurrency {
  currencyDisplay?: string;
  codeOnFront?: boolean;
}

export default function useCurrency() {
  const currencyCodeReplacements: RegExp = /ISK|ZAR|BTC/;
  const userStore = useUserStore();
  const { currentUser } = storeToRefs(userStore);

  function currencyFromCode(currencyCode: string): Currency | undefined {
    return currencies.find((currency) => currency.code === currencyCode);
  }

  function currencyFromLocale(locale: string): Currency | undefined {
    return currencies.find((currency) => currency.locale === locale);
  }

  function numberToCurrency(
    number: number,
    { locale, currencyCode, currencyDisplay, codeOnFront }: NumberToCurrency
  ): string {
    const currency = getLocaleNumber(
      number,
      {
        style: "currency",
        currency: currencyCode,
        minimumFractionDigits: 0,
        currencyDisplay,
      },
      (locale ? `${locale}-u-nu-latn` : null) as any
    );

    if (currencyDisplay === "symbol") {
      const currencyCodeMatches = currency.match(currencyCodeReplacements);

      if (currencyCodeMatches) {
        const symbol = currencyFromCode(currencyCodeMatches[0])?.symbolNative;

        if (symbol) return currency.replace(currencyCodeMatches[0], symbol);
      }
    } else if (currencyDisplay === "code") {
      if (codeOnFront) {
        const code = currency.match(/[A-Z]+/);

        if (code) {
          return `${code[0]} ${currency.replace(code[0], "").trim()}`;
        }
      }
    }

    return currency;
  }

  const currentUserCurrency = computed<Currency | undefined>(
    () =>
      currencyFromCode(currentUser.value.currency_code ?? "") ||
      currencyFromLocale(getLocale())
  );

  function numberToCurrentUserCurrency(
    number: number,
    {
      currencyDisplay = "symbol",
      codeOnFront = false,
    }: NumberToCurrentUserCurrency = {}
  ): string {
    return currentUserCurrency.value
      ? numberToCurrency(number, {
          locale: currentUserCurrency.value.locale,
          currencyCode: currentUserCurrency.value.code,
          currencyDisplay,
          codeOnFront,
        })
      : numberToCurrency(number, {
          locale: "en",
          currencyCode: "AUD",
          currencyDisplay,
          codeOnFront,
        });
  }

  return {
    currentUserCurrency,
    numberToCurrentUserCurrency,
  };
}
