<template>
  <div>
    <UpgradeBar />
    <Transition
      appear
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <Splashscreen v-if="displaySplashscreen">
        <div
          v-if="globalDataError"
          class="q-ma-md"
        >
          There was an error loading your account.
          <a
            class="hover-active cursor-pointer"
            @click="getAppData"
          >
            Click to retry
          </a>
        </div>
      </Splashscreen>
    </Transition>
    <RouterView
      v-slot="{ Component }"
      :key="routerViewKey"
    >
      <Transition
        appear
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut"
      >
        <div v-if="displayApp">
          <UniversalSearch
            v-if="isLoggedIn"
            v-show="searchOpen"
            @close="hideSearch"
          />
          <div class="full-width layout-wrapper">
            <Component :is="Component" />
          </div>
          <VueDraggableResizable
            v-show="playerOpen"
            w="auto"
            h="auto"
            :z="20"
            class-name="player-popout-dragabble"
            drag-cancel=".drag-cancel"
            :style="{ width: playerPopoutWidth }"
            :resizable="false"
          >
            <PortalTarget :name="POPOUT_PLAYER_TARGET" />
          </VueDraggableResizable>
          <Transition name="slide">
            <SideBar
              v-show="sideBarOpen"
              @close="hideSideBar"
            />
          </Transition>

          <PlayerPopout @close="playerHide" />
        </div>
      </Transition>
    </RouterView>
  </div>
</template>

<script>
import { storeToRefs } from "pinia";
import { PortalTarget } from "portal-vue";

import PlayerPopout from "shared/components/players/PlayerPopout.vue";
import UniversalSearch from "shared/components/search/UniversalSearch.vue";
import { POPOUT_PLAYER_TARGET } from "shared/constants";
import AlertsPoller from "shared/services/polling/AlertsPoller";
import MediaRequestsPoller from "shared/services/polling/MediaRequestsPoller";
import MentionCountsPoller from "shared/services/polling/MentionCountsPoller";
import TranscriptsPoller from "shared/services/polling/TranscriptsPoller";
import { useGlobalStore } from "shared/stores/global";
import { useMentionCountsStore } from "shared/stores/mentionCounts";
import { useStreamsStore } from "shared/stores/streams";
import { useUniversalPlayerStore } from "shared/stores/universalPlayer";
import { useUniversalSearchStore } from "shared/stores/universalSearch";
import { useUserStore } from "shared/stores/user";

import SideBar from "src/components/SideBar.vue";
import Splashscreen from "src/components/Splashscreen.vue";
import UpgradeBar from "src/components/UpgradeBar.vue";
import { GoogleLoginPoller, refreshGoogleLogin } from "src/services/googleSso";
import {
  MicrosoftLoginPoller,
  refreshMicrosoftLogin,
} from "src/services/microsoftSso";
import { useDesktopStore } from "src/stores/desktop";
import { useSideBarStore } from "src/stores/sideBar";

export default {
  name: "App",
  components: {
    PortalTarget,
    PlayerPopout,
    SideBar,
    UniversalSearch,
    UpgradeBar,
    Splashscreen,
  },
  setup() {
    const mentionCountsStore = useMentionCountsStore();
    const { mentionCount } = storeToRefs(mentionCountsStore);

    const streamsStore = useStreamsStore();
    const { primaryStreams } = storeToRefs(streamsStore);

    const universalPlayerStore = useUniversalPlayerStore();
    const { playerOpen, playerIsExpanded } = storeToRefs(universalPlayerStore);
    const { playerHide } = universalPlayerStore;

    const universalSearchStore = useUniversalSearchStore();
    const { hideSearch } = universalSearchStore;
    const { searchOpen } = storeToRefs(universalSearchStore);

    const desktopStore = useDesktopStore();
    const { getOrganisationReportSpec } = desktopStore;

    const sideBarStore = useSideBarStore();
    const { hideSideBar } = sideBarStore;
    const { sideBarOpen } = storeToRefs(sideBarStore);

    const globalStore = useGlobalStore();

    const { globalDataLoaded, globalDataError, routerViewKey } =
      storeToRefs(globalStore);

    const { getAppData } = globalStore;

    const userStore = useUserStore();

    const {
      currentUser,
      isLoggedIn,
      isAdminUser,
      accountManager,
      enforceTwoFactor,
      identityProvider,
    } = storeToRefs(userStore);

    const { adminUserLogin } = userStore;

    return {
      mentionCount,
      primaryStreams,

      playerOpen,
      playerIsExpanded,
      playerHide,

      hideSearch,
      searchOpen,

      getOrganisationReportSpec,

      hideSideBar,
      sideBarOpen,

      globalDataLoaded,
      globalDataError,
      getAppData,

      currentUser,
      isLoggedIn,
      isAdminUser,
      accountManager,
      enforceTwoFactor,
      identityProvider,
      adminUserLogin,
      routerViewKey,
    };
  },
  data() {
    return {
      POPOUT_PLAYER_TARGET,
    };
  },
  computed: {
    displaySplashscreen() {
      return (
        this.isLoggedIn &&
        !this.globalDataLoaded &&
        !this.$route.meta?.public &&
        !this.enforceTwoFactor
      );
    },
    displayApp() {
      return (
        this.enforceTwoFactor ||
        this.$route.meta?.public ||
        !this.isLoggedIn ||
        (this.globalDataLoaded && !this.globalDataError)
      );
    },
    playerPopoutWidth() {
      return this.playerIsExpanded ? "700px" : "347px";
    },
  },
  watch: {
    isLoggedIn: {
      immediate: true,
      handler() {
        this.togglePolling(this.isLoggedIn);
      },
    },
  },
  async mounted() {
    // Don't do any login/data loading if public route
    if (this.$route.meta?.public) return;

    // handle login via admin mode
    await this.tryAdminLogin();

    // If user is logged in, we load data needed by most of the pages
    // Otherwise we will load data after the login
    // Until global data are loaded, a spinner is displayed on
    // layouts/default instead of the router view

    if (this.isLoggedIn) {
      if (this.identityProvider === "microsoft") {
        try {
          await refreshMicrosoftLogin();
          MicrosoftLoginPoller.init();
        } catch {
          return;
        }
      }

      if (this.identityProvider === "google") {
        try {
          await refreshGoogleLogin();
          GoogleLoginPoller.init();
        } catch {
          return;
        }
      }

      await this.getAppData();
    }

    // Load data for the admin mode toolbar
    if (this.isAdminUser) {
      await this.getOrganisationReportSpec();
    }
  },
  methods: {
    async tryAdminLogin() {
      try {
        const queryParams = new URLSearchParams(window.location.search);
        // eslint-disable-next-line camelcase
        const token = queryParams.get("auth_token");

        if (token) {
          // eslint-disable-next-line camelcase
          const accountManager = JSON.parse(
            queryParams.get("account_manager") || "{}"
          );

          const redirect = queryParams.get("redirect") || "/";
          await this.adminUserLogin({ token, accountManager });
          this.$safeRouterReplace(redirect);
        }
      } catch {
        // ignore try admin login errors
      }
    },
    togglePolling(startPolling) {
      if (startPolling) {
        MentionCountsPoller.init();
        AlertsPoller.init();
        MediaRequestsPoller.init();
        TranscriptsPoller.init();
      } else {
        MentionCountsPoller.stop();
        AlertsPoller.stop();
        MediaRequestsPoller.stop();
        TranscriptsPoller.stop();
        MicrosoftLoginPoller.stop();
        GoogleLoginPoller.stop();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "shared/css/utils/transitions/slideRight";

.layout {
  width: 100%;
  transition: 0.3s;
}

.layout-wrapper {
  position: relative;
}

.player-popout-dragabble {
  border: none;
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 1;
}
</style>
