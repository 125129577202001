<template>
  <div
    v-intersection="handleIntersection"
    class="c-chart-library-chart"
    :style="chartStyle"
  >
    <DataLoadingWrapper
      :state="state"
      class="column no-wrap"
      @reload="emit('reload')"
    >
      <slot name="before" />

      <Transition name="fade">
        <Component
          :is="chartComponent"
          v-if="isIntersecting"
          v-bind="chartProps"
          class="fit self-center"
          :interaction-enabled="Boolean(chartConfig.interactionEnabled)"
          @chart-clicked="emit('chart-clicked', $event)"
          @chart:render="emit('chart:render', $event)"
        />
      </Transition>
    </DataLoadingWrapper>
  </div>
</template>

<script setup lang="ts">
import type { Chart } from "chart.js";
import { merge } from "lodash-es";
import type { IntersectionValue } from "quasar";
import { type Component, computed, type CSSProperties, ref } from "vue";

import { appInstance } from "shared/boot/app";
import { DataLoadingWrapper } from "shared/components/base";
import { DataLoadingWrapperState } from "shared/types";
import type { ChartLibraryProps } from "shared/types/charts";
import type { ChartConfig } from "shared/types/instantInsights/chartConfig";
import type { ChartClickEvent } from "shared/types/instantInsights/chartInteractions";

const props = defineProps<{
  chartConfig: ChartConfig;
  chartData: ChartLibraryProps;
  loading?: boolean;
  error?: boolean;
  intersectOnce?: boolean;
}>();

const emit = defineEmits<{
  reload: [];
  "chart-clicked": [ChartClickEvent];
  "chart:render": [Chart];
  intersection: [boolean];
}>();

const isIntersecting = ref(false);

const state = computed<DataLoadingWrapperState>(() => {
  if (props.loading) return DataLoadingWrapperState.Loading;
  if (props.error) return DataLoadingWrapperState.Error;

  return DataLoadingWrapperState.Done;
});

const chartComponent = computed<Component | undefined>(() =>
  appInstance.component(props.chartConfig.component)
);

const chartStyle = computed<CSSProperties | undefined>(
  () => props.chartConfig.style
);

const chartProps = computed<ChartLibraryProps>(() =>
  merge({}, props.chartData, props.chartConfig.componentAttributes)
);

const handleIntersection: IntersectionValue = (el) => {
  isIntersecting.value = el.isIntersecting;
  emit("intersection", isIntersecting.value);

  if (isIntersecting.value && props.intersectOnce) {
    return false;
  }

  return true;
};
</script>

<style lang="scss" scoped>
.c-chart-library-chart {
  width: 100%;
}
</style>
