import { ref } from "vue";

import generateId from "shared/helpers/generateId";

// @see https://portal-vue.linusb.org/api/portal-target.html#events-api
export type PortalChangeEvent = {
  hasContent: boolean;
  sources: string[];
};

// eslint-disable-next-line import/prefer-default-export
export function usePortalContent(portalName: string) {
  const hasPortalContent = ref(false);

  // this is provided by PortalTarget
  const handlePortalChange = (event: PortalChangeEvent) => {
    const hasNewContent = event?.hasContent ?? false;

    if (hasPortalContent.value !== hasNewContent) {
      hasPortalContent.value = hasNewContent;
    }
  };

  return {
    portalName: `${portalName}-${generateId()}`,
    hasPortalContent,
    handlePortalChange,
  };
}
